import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import Heading from "@elements/heading"
import Paragraph from "@elements/paragraph"
import Flex from "@components/elements/flex"
import Anchor from "@elements/anchor"
import { graphql } from "gatsby"
import Html from "@components/html"
import Breadcrumb from "@components/blog/breadcrumb"

const Career = ({ data, pageContext }) => {
  const career = data.careers
  const links = [
    { text: "Careers", url: "/about/careers" },
    { text: career.title, url: `/about/careers/${career.slug}` },
  ]
  const mailto = `mailto:careers@klipfolio.com?subject=${career.title}`
  return (
    <Layout
      canonical={pageContext.slug}
      title={career.title}
      description={career.seo_summary}
    >
      <Flex gap="2rem">
        <Container text>
          <Flex margin="2rem 0 4rem" gap="2rem">
            <Breadcrumb links={links} noPadding />
            <Heading fontSize="2.5rem">{career.title}</Heading>
            <Flex gap="0.5rem">
              <Paragraph>
                <b>Reports to:</b> {career.reports_to}
              </Paragraph>
              <Paragraph>
                <b>Works with:</b> {career.works_with}
              </Paragraph>
              <Paragraph>
                <b>Level:</b> {career.level}
              </Paragraph>
              <Paragraph>
                <b>Location:</b> {career.location}
              </Paragraph>
            </Flex>
            <Flex gap="2rem" fontSize="1.2rem">
              <Html parseElements html={career.description} />
            </Flex>
            <div>
              <Paragraph>
                <b>
                  Send your resume to{" "}
                  <Anchor link={mailto}>careers@klipfolio.com</Anchor>
                </b>
              </Paragraph>
              <Paragraph margin="0 0 2rem">
                {`Ensure the subject line of your email contains `}
                <b>{`"${career.title}"`}</b>
              </Paragraph>
            </div>
          </Flex>
        </Container>
      </Flex>
    </Layout>
  )
}

Career.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CareerQuery($id: String!) {
    careers(id: { eq: $id }) {
      title
      reports_to
      works_with
      level
      location
      seo_summary
      description
      extra
    }
    perks: allPerks(filter: { status: { eq: "published" } }) {
      edges {
        node {
          benefit
          icon {
            title
            cdn
            placeholder
            id
          }
        }
      }
    }
  }
`

export default Career
